<template>
    <div>
        <span>{{ $t('shop.info_key') }} <el-link href="https://www.mageplaza.com/kb/how-to-create-new-admin-user-magento-2.html" target="_blank" type="primary"><span style="color: #00a3d4">link</span></el-link></span>
        <br><br>
        <el-button type="warning" @click="showRequiredResources">{{ $t('shop.api_required_resources') }}</el-button>
        <div v-if="showRequiredResourcesList">
            <ul>
                <li v-for="(rule, index) in requiredResources" :key="index">{{ rule }}</li>
            </ul>
        </div>

        <div class="mini-separator"></div>
        <el-input :placeholder="$t('shop.name')" v-model="shop.name"></el-input>

        <div class="mini-separator"></div>
        <span>Format: <strong>https://website.ro/</strong></span>
        <el-input :placeholder="$t('shop.site_url')" v-model="shop.shopUrl"></el-input>

        <div class="mini-separator"></div>
        <el-input :placeholder="$t('shop.add_api_username')" v-model="shop.api_username"></el-input>

        <div class="mini-separator"></div>
        <el-input :placeholder="$t('shop.add_api_password')" v-model="shop.api_password"></el-input>

        <template v-if="shop.connect">
            <div class="mini-separator"></div>
            <span>Pentru a activa sincronizarea do comenzi completati datele din tab-ul
                                    <a href="#" @click.prevent="openSettingsTab" style="color: blue">Settings</a>
                                </span>
        </template>

        <div class="mini-separator"></div>
        <template v-if="shop.name.length > 3 && shop.api_username.length > 2 && shop.api_password.length > 5 && shop.shopUrl.length > 10">
            <el-button @click="connect" type="primary">{{ $t('shop.check_conn') }}</el-button>
        </template>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                rules: {
                    show: false,
                    message: '',
                    data: [],
                    additionalResources: {}
                },
                requiredResources: {},
                showRequiredResourcesList: false,
            };
        },
        props: {
            shop: {
                type: Object,
            },
            platform: {
                type: String,
            }
        },
        methods: {
            showRequiredResources() {
                this.showRequiredResourcesList = !this.showRequiredResourcesList;
                const countResources = Object.keys(this.requiredResources).length;
                if (countResources === 0) {
                    this.$store.dispatch('integrations/getRequiredResources', {
                        platform: this.platform || this.shop.platform,
                    })
                        .then((res) => {
                            if (res.error === 0) {
                                this.requiredResources = res.message.requiredResources;
                            }
                        }).catch(() => {
                    });
                }
            },
            connect() {
                localStorage.setItem('shopUrl', this.shop.shopUrl);
                this.rules = {
                    show: false,
                    message: '',
                    data: []
                };
                this.$store.dispatch('integrations/checkConnectionToShop', {
                    ...this.shop,
                    platform: this.platform
                })
                    .then((res) => {
                        if (res.error === true) {
                            this.$notify.error(this.$t('shop.conn_err'));
                        }
                        if (res.error === 0) {
                            this.$router.push({
                                name: 'shopView',
                                params: {
                                    id: res.message.id
                                }
                            }).catch(() => {});
                        }
                    }).catch(() => {
                        this.$notify.error('Err W-M2-001');
                    });
            },
            openSettingsTab() {
                this.activeTab = 'settings';
            }
        }
    };
</script>